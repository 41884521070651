// Site Structure

const DATA_TYPES = Object.freeze({
  PETITION: { basePath: 'petitions', trigger: 'Sign', typeName: 'petition' },
  DRIVE: { basePath: 'drives', trigger: 'Endorse', typeName: 'drive' },
  LETTER: { basePath: 'letters', trigger: 'Send', typeName: 'letter' },
  CANDIDATE: { basePath: '/drives/explore/candidate', typeName: 'candidate' },
  USER: { basePath: 'go', trigger: 'Follow', typeName: 'user' },
  REVIEW: { basePath: '', trigger: 'review', typeName: 'review' },
  OFFICIAL: { basePath: 'officials', trigger: '', typeName: 'official' },
});

// https://github.com/resistbot/themis/blob/main/themis/graphql/schema.graphql#L59
const PROMOTE_STATUSES = Object.freeze({
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  DELIVERED: 'DELIVERED',
  EXPIRED: 'EXPIRED',
  DECLINED_REFUNDED: 'DECLINED_REFUNDED',
  EXPIRED_REFUNDED: 'EXPIRED_REFUNDED',
});

const LETTER_STATUSES = Object.freeze({
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
});

const CHANNEL_PRIORITY_ORDER = [
  'apple',
  'messenger',
  'telegram',
  'instagram',
  'sms',
];
const CHANNEL_PARAMETER = 'channel';

const config = {
  // Note: Comes from next.config.js so doesn't map 1:1 with this actual env var
  // We fall back to resist.bot to satisfy Chromatic tests where reading the env is very different.
  siteUrl: process.env.SITE_URL || 'https://resist.bot',
  title: `Resistbot`,
  description: `I'm Resistbot. Text RESIST to 50409 and I'll help you contact your representatives.`,
  themeColor: '#0074e0',
  // Default, currently used in Blog feed top-level item.
  blog: {
    author: 'Resistbot',
    authorTwitterUsername: `@resistbot`,
    baseURL: 'news',
  },
  lettersToTheEditor: {
    sheetID: '1fBeK7TtHTGetUDslTOcOV0H2vlh3spffxCV8qfLP2qU',
  },
  channelUrls: {
    apple:
      'https://bcrw.apple.com/sms:open?service=iMessage&recipient=urn:biz:d0189b65-f844-4344-adc1-d2d01daca04e&body=',
    telegram: 'https://t.me/resistbot?start=',
    messenger: 'https://www.messenger.com/t/1224106861035505?text=',
    sms: 'sms://50409?&body=',
    instagram: 'https://www.instagram.com/direct/t/107534807309194/?text=',
    whatsapp: 'https://wa.me/12023354008?text=',
  },
  miscUrls: {
    iosApp: 'https://apps.apple.com/us/app/resistbot/id1564775862',
    podSubscribe: 'https://resistbot.live/subscribe',
  },
  shopUrls: {
    cottonBureau: 'https://cottonbureau.com/people/resistbot',
    myFanThreads: 'https://myfanthreads.com/collections/resistbot',
  },
  donateUrls: {
    stripe: 'https://donate.stripe.com/cN2cNtbgw7aWeB2aEE',
    venmo: 'https://venmo.com/resistbot',
    cashApp: 'https://cash.app/resistbot',
    crypto:
      'https://commerce.coinbase.com/checkout/cc4f9e49-f18e-4cf2-a1b7-ecbeafcb3491',
    paypal: 'https://www.paypal.com/donate/?hosted_button_id=PK67PYRWZQTZ2',
    actBlue: 'https://secure.actblue.com/donate/resistbot',
  },
  socialUrls: {
    twitter: 'https://twitter.com/intent/follow?screen_name=resistbot',
    facebook: 'https://facebook.com/resistbot',
    discord: 'https://rs.bot/discord',
    applePod:
      'https://podcasts.apple.com/us/podcast/the-resistbot-podcast/id1587774264',
    instagram: 'https://instagram.com/resistbot',
    youtube: 'https://www.youtube.com/channel/UCEiQinjQvseC-U7Bs6sSpVQ',
    tiktok: 'https://www.tiktok.com/@resistbot',
    mastodon: 'https://botsin.space/@resistbot',
    openletters: 'https://botsin.space/@openletters',
    substack: 'https://resistbot.substack.com',
    threads: 'https://www.threads.net/@resistbot',
  },
  emailAddresses: {
    support: 'support@resist.bot',
    volunteer: 'volunteer@resist.bot',
  },
  company: {
    address: '802 E. Whiting St., Tampa, FL 33602',
    phone: '202-335-4008',
  },
};

export default config;
export {
  DATA_TYPES,
  PROMOTE_STATUSES,
  LETTER_STATUSES,
  CHANNEL_PRIORITY_ORDER,
  CHANNEL_PARAMETER,
};
