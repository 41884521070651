import { BuildData } from 'components/footer';

import Container from 'components/container';
import Anchor from 'components/typography/anchor';
import Img from 'next/image';
import Bubble from 'components/bubble';
import messenger from 'images/icons/messenger.svg';
import instagram from 'images/icons/instagram.svg';
import telegram from 'images/icons/telegram.svg';
import tiktok from 'images/icons/tiktok.svg';
import substack from 'images/icons/substack.svg';
import mastodon from 'images/icons/mastodon.svg';
import discord from 'images/icons/discord.svg';
import threads from 'images/icons/threads.svg';
import config from 'siteconfig';

const Footer = () => {
  return (
    <footer
      role="contentinfo"
      className="bg-stone-50 bg-footer bg-cover bg-center py-10 dark:bg-stone-950 dark:bg-blend-hard-light print:hidden"
    >
      <Container>
        <nav className="mb-4 flex flex-wrap gap-x-4">
          <Anchor className="anchor-subtle" href="/">
            Home
          </Anchor>
          <Anchor className="anchor-subtle" href="/premium">
            Premium
          </Anchor>
          <Anchor className="anchor-subtle" href="/premium/coins">
            Buy Coins
          </Anchor>
          <Anchor className="anchor-subtle" href="/guide">
            Guide
          </Anchor>
          <Anchor className="anchor-subtle" href="/petitions">
            Petitions
          </Anchor>
          <Anchor className="anchor-subtle" href="/letters">
            Open Letters
          </Anchor>
          <Anchor className="anchor-subtle" href="/officials">
            Officials
          </Anchor>
          <Anchor className="anchor-subtle" href={config.shopUrls.cottonBureau}>
            Shop
          </Anchor>
          <Anchor className="anchor-subtle" href="/about">
            Help
          </Anchor>
          <Anchor className="anchor-subtle" href={`/${config.blog.baseURL}`}>
            News
          </Anchor>
        </nav>
        <p className="mb-4 text-sm">
          Resistbot is a free service, but message and data rates may apply if
          you use the service over SMS. Message frequency varies. Text{' '}
          <Bubble>stop</Bubble> to 50409 to stop all messages. Text{' '}
          <Bubble>help</Bubble> to 50409 for help. Here are our{' '}
          <Anchor className="anchor-subtle" href="/terms">
            terms&nbsp;of&nbsp;use
          </Anchor>
          ,{' '}
          <Anchor className="anchor-subtle" href="/privacy">
            privacy&nbsp;notice
          </Anchor>{' '}
          and{' '}
          <Anchor className="anchor-subtle" href="/rights">
            user bill of&nbsp;rights
          </Anchor>
          .
        </p>
        <p className="mb-4 text-sm">
          Resistbot is a product <em>of</em> the Resistbot Action Fund, a
          501(c)(4) social welfare organization. Since we lobby on your behalf,
          donations are not tax-deductible as charitable&nbsp;contributions.
        </p>
        <p className="mb-4 text-sm">
          {config.company.address}
          <br />
          {config.company.phone}
        </p>
        <section className="mb-6 flex size-fit flex-wrap items-center">
          <Anchor
            href={config.channelUrls.telegram}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={telegram}
              alt="Telegram"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.channelUrls.messenger}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={messenger}
              alt="Messenger"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.channelUrls.instagram}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={instagram}
              alt="Instagram"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.socialUrls.discord}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={discord}
              alt="Discord"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.socialUrls.mastodon}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={mastodon}
              alt="Mastodon"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.socialUrls.substack}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={substack}
              alt="Substack"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.socialUrls.tiktok}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={tiktok}
              alt="Tiktok"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
          <Anchor
            href={config.socialUrls.threads}
            className="p-3.5 opacity-30 transition-opacity hover:opacity-80"
          >
            <Img
              src={threads}
              alt="Threads"
              height="20"
              width="20"
              className="h-4 dark:invert"
            />
          </Anchor>
        </section>
        <BuildData />
      </Container>
    </footer>
  );
};

export default Footer;
